.special-offer {
    background: #628f3d;
    border-radius: 10px;
    padding: 15px 10px;
    color: white;
    text-align: center;
    margin: 15px -60px 15px 0;

    font-size: 13px;
}

.special-offer h3 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px;
}

.special-offer footer {
    background: url('../mini-logo.svg') no-repeat 0 0 / 20px 20px;
    min-height: 20px;
    padding-left: 26px;
    display: inline-block;
    margin-left: -10px;
}
