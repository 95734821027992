.c-cp-body {
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  background: #fff;
}

.c-cp-cover {
  position: relative;
  -webkit-flex: none;
  flex: none;
  background-color: #000;
}

.c-cp-cover {
  min-height: 160px;
}

.c-cp-cover__cover {
  position: absolute;
  width: 100%;
  height: 100%;
}

.c-cp-cover__overlay {
  position: absolute;
  width: 100%;
}

.c-cp-cover__overlay {
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5) 0,
    rgba(0, 0, 0, 0.6) 70%,
    rgba(0, 0, 0, 0.9) 100%
  );
}

.c-cp-cover__wrapper {
  position: relative;
  height: 100%;
  color: #ccc;
}

.c-cp-cover__wrapper {
  box-sizing: border-box;
  text-align: center;
}

.c-cp-cover__body {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.c-cp-cover__body {
  min-height: 160px;
}

.c-cp-cover__header {
  color: #fff;
  box-sizing: border-box;
}

.c-cp-cover__header {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 0 40px 12px;
  padding: 10px 0;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.9);
}

.c-cp-cover__header h1 {
  font-family: 'Open Sans', 'Helvetica Neue', HelveticaNeue, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', rouble;
}

.c-cp-cover__header h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
}

.c-cp-main {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 3px;
}

.c-cp-main__avatar {
  display: table-cell;
  width: 52px;
  vertical-align: top;
}

.c-cp-main__pic {
  position: relative;
  border: 1px solid #f0f0f0;
  border-radius: 15px;
  overflow: hidden;
}

.m .c-cp-main__pic {
  isolation: isolate;
}

.c-cp-main__body {
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
}

.c-cp-main__body h1 {
  margin: -4px 0 2px;
  font-size: 17px;
  line-height: 1;
  font-weight: 700;
}

.c-cp-main__rating {
  font-size: 13px;
}

.c-cp-main__online {
  font-size: 13px;
}

.c-cp-main__stats {
  display: -webkit-flex;
  display: flex;
}

.c-cp-main__stats {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  margin-bottom: 17px;
}

.c-cp-main__stats > * {
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto;
}

.c-cp-main__status {
  color: #333;
}

.c-cp-main__status {
  font-size: 13px;
}

.c-cp-main__closed {
  color: #c03;
}

.c-cp-main__status > div:not(:first-child) {
  margin-top: 9px;
}

.c-cp-main__status > div:not(:first-child) {
  margin-top: 5px;
}

.c-cp-main__online-status--online {
  color: #5fa03d;
  font-weight: 700;
}

.c-cp-stats {
  position: relative;
  color: #333;
}

.c-cp-stats {
  font-size: 12px;
  line-height: 1.25;
  text-align: center;
}

.c-cp-stats strong {
  display: block;
  line-height: 1.25;
}

.c-cp-stats strong {
  font-family: 'Open Sans', HelveticaNeueThin, HelveticaNeue-Thin, 'Helvetica Neue Thin',
    'Open Sans', 'Helvetica Neue', HelveticaNeue, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', rouble;
  font-size: 34px;
  font-weight: 300;
}

.c-cp-nav {
  font-size: 15px;
  border-top: 1px solid #ccc;
}

.c-cp-nav__item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: #333;
  font-weight: 700;
  line-height: 39px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
}

.c-cp-nav__item > div {
  -webkit-flex: none;
  flex: none;
  width: 45px;
}

.c-cp-nav__item svg {
  display: block;
  width: 22px;
  height: 22px;
  margin-left: auto;
  fill: currentColor;
}

.c-cp-nav__item--messages svg {
  width: 28px;
  height: 20px;
}

.c-cp-nav__item--phone svg {
  margin-right: 3px;
}

.c-cp-nav__item--booking svg {
  width: 23px;
  height: 24px;
  margin-right: 2px;
}

.c-cp-nav__item--blog svg {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.c-cp-nav__item--photos svg {
  width: 28px;
  height: 28px;
}

.c-cp-nav__item--reviews svg {
  width: 26px;
  height: 26px;
  margin-right: 1px;
}

.c-cp-nav__item--prices svg {
  margin-right: 3px;
}

.c-cp-nav__item--contacts svg {
  width: 16px;
  height: 23px;
  margin-right: 6px;
}

.c-cp-nav span {
  display: block;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-left: 10px;
}

.c-cp-nav__spacer {
  margin-top: 35px;
  border-bottom: 1px solid #ccc;
}

.c-cp-block:last-child {
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto;
  padding-bottom: 160px;
}

.c-cp-block:last-child {
  padding-bottom: 80px;
}

.c-cp-block:nth-of-type(2n) {
  background-color: #f0f0f0;
}

.c-cp-block--mod {
  position: relative;
  background-color: #ffc !important;
}

.c-cp-block--alt {
  background-color: #f0f0f0;
}

.c-cp-block__wrapper {
  padding: 20px 20px 0;
}

.c-cp-block__wrapper--alt {
  background-color: #fff;
}

.c-cp-block--mod .c-cp-block__wrapper {
  padding-top: 0;
}

.c-cp-block--mod .c-cp-block__wrapper {
  padding-top: 0;
}

.c-cp-block__wrapper:after {
  clear: both;
  display: table;
  content: '';
  margin-top: 80px;
}

.c-cp-block__wrapper:after {
  margin-top: 40px;
}

.c-cp-block--alt .c-cp-block__wrapper:after {
  margin-top: 40px;
}

.c-cp-block--alt .c-cp-block__wrapper:after {
  margin-top: 20px;
}

.c-cp-block--mod .c-cp-block__wrapper:after {
  margin-top: 0;
}

.c-cp-block--mod .c-cp-block__wrapper:after {
  margin-top: 0;
}

.c-cp-header {
  line-height: 1;
  text-align: center;
}

.c-cp-header {
  font-size: 20px;
  margin: -3px 0 20px;
}

.c-cp-sub-header {
  color: grey;
  line-height: 1.6;
  text-align: center;
}

.c-cp-sub-header {
  margin: -14px 0 18px;
  font-size: 13px;
}

.c-cp-photo {
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  overflow: hidden;
}

.c-cp-photo {
  isolation: isolate;
}

.c-cp-photo {
  border-radius: 6px;
}

.c-cp-about {
  max-width: 720px;
}

.c-cp-about__wrapper {
  min-height: 200px;
}

.c-cp-about__avatar {
  position: relative;
  float: right;
  width: 200px;
  height: 200px;
  margin: 6px 0 20px 20px;
  border-radius: 15px;
  overflow: hidden;
}

.c-cp-about__avatar {
  isolation: isolate;
}

.c-cp-about__avatar {
  width: 100px;
  height: 100px;
  margin: 4px 0 10px 10px;
}

.c-cp-offers {
  max-width: 720px;
}

.c-cp-offers-table {
  width: 100%;
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 1.25;
}

.c-cp-offers-table {
  table-layout: fixed;
  margin-bottom: 15px;
  font-size: 17px;
}

.c-cp-offers-table td,
.c-cp-offers-table th {
  padding: 0 0 25px;
  vertical-align: top;
  font-weight: 400;
}

.c-cp-offers-table td,
.c-cp-offers-table th {
  padding: 0 0 10px;
}

.c-cp-offers-table td {
  text-align: right;
  white-space: nowrap;
  padding-left: 10px;
}

.c-cp-post-card__pic {
  border-radius: 15px;
  overflow: hidden;
}

.c-cp-post-card__pic {
  isolation: isolate;
}

.c-cp-post-card__caption {
  margin: 13px 0 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
  word-wrap: break-word;
}

.c-cp-post-card__caption {
  font-size: 17px;
}

.c-cp-post-card__info {
  color: grey;
  font-size: 12px;
  line-height: 14px;
}

.c-cp-post-card__info {
  position: static;
  font-size: 13px;
  margin: 10px 0 5px;
}

.c-cp-post-card__comments,
.c-cp-post-card__date {
  margin-left: 17px;
}

.c-cp-post-card-m {
  position: relative;
}

.c-cp-post-card-m__body {
  font-size: 12px;
  word-wrap: break-word;
  padding-top: 6px;
  box-sizing: border-box;
  vertical-align: top;
  font-size: 13px;
}

.c-cp-post-card-m__caption {
  margin-bottom: 2px;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.25;
  color: #205090;
  max-height: 49px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.c-cp-post-card-m__info {
  font-size: 11px;
  margin: 3px 0 0;
}

.c-cp-post-card-m__comments,
.c-cp-post-card-m__date {
  margin-left: 8px;
}

.c-cp-map {
  position: relative;
  margin-bottom: 40px;
  background: #999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.c-cp-map {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 20px;
}

.c-cp-map:after {
  content: '';
  display: block;
  padding-top: 56.25%;
}

.c-cp-map:after {
  padding-top: 75%;
}

.c-cp-map__map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-cp-contacts {
  font-size: 20px;
  line-height: 1.5;
}

.c-cp-info {
  margin-bottom: -10px;
}

.c-cp-info__phone {
  color: #205090;
}

.c-cp-working-hours {
  display: table;
  font-size: 20px;
}

.c-cp-working-hours {
  font-size: 17px;
}

.c-cp-working-hours li {
  display: table-row;
}

.c-cp-working-hours li.is-active {
  font-weight: 700;
}

.c-cp-working-hours strong {
  display: table-cell;
  padding-right: 1em;
  vertical-align: top;
  font-weight: 400;
}

.c-cp-working-hours li.is-active strong {
  font-weight: 700;
}

.c-cp-working-hours span {
  display: table-cell;
  vertical-align: top;
}

.c-cp-social-links {
  font-size: 20px;
  line-height: 1.5;
}

.c-cp-social-links {
  margin-top: 4px;
  font-size: 17px;
}

.c-cp-social-links__item {
  display: -webkit-flex;
  display: flex;
  margin: 0 0 13px -5px;
}

.c-cp-social-links__icon {
  -webkit-flex: none;
  flex: none;
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 7px -7px 0;
  fill: grey;
}

.c-cp-social-links__icon {
  margin-top: -3px;
}

.c-cp-social-links__body {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  display: block;
  min-width: 0;
}

@-webkit-keyframes zoomout-alt {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }
}

@keyframes zoomout-alt {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }
}

.c-cp-phone {
  position: relative;
  fill: currentColor;
  white-space: nowrap;
}

.c-cp-phone svg {
  width: 1em;
  height: 1em;
  margin: -0.1em 0.05em 0 0;
  vertical-align: middle;
}

.c-cp-blog-buttons {
  text-align: center;
  margin-top: 62px;
}

.c-cp-blog-buttons {
  margin-top: 32px;
}

.c-cp-feedback-stub {
  background-color: #fff;
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.15);
  text-align: center;
  box-sizing: border-box;
}

.c-cp-feedback-stub {
  position: relative;
  padding: 10px 20px 24px;
  border-radius: 6px;
}

.c-cp-feedback-stub__icon {
  color: #e8e8e8;
}

.c-cp-feedback-stub__icon {
  font-size: 20px;
}

.c-cp-feedback-stub__icon svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.c-cp-feedback-stub__icon svg:first-child {
  margin-right: 4px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.c-cp-feedback-stub__header {
  font-family: Georgia, serif, rouble;
  font-style: italic;
  line-height: 1.25;
}

.c-cp-feedback-stub__header {
  margin-bottom: 7px;
  font-size: 18px;
}

.c-cp-feedback-stub__text {
  margin-bottom: 15px;
  font-size: 13px;
}

.c-cp-feedback-stub__text p {
  margin: 0;
}

.c-cp-feedback-stub__text p + p {
  margin-top: 0.8em;
}

@-webkit-keyframes sign-swing {
  0% {
    -webkit-transform: rotate(-3.5deg);
    transform: rotate(-3.5deg);
  }

  50% {
    -webkit-transform: rotate(-2.5deg);
    transform: rotate(-2.5deg);
  }

  100% {
    -webkit-transform: rotate(-3.5deg);
    transform: rotate(-3.5deg);
  }
}

@keyframes sign-swing {
  0% {
    -webkit-transform: rotate(-3.5deg);
    transform: rotate(-3.5deg);
  }

  50% {
    -webkit-transform: rotate(-2.5deg);
    transform: rotate(-2.5deg);
  }

  100% {
    -webkit-transform: rotate(-3.5deg);
    transform: rotate(-3.5deg);
  }
}

.c-cp-sign-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 105%;
  height: 105%;
  background: rgba(231, 231, 231, 0.8);
  overflow-y: scroll;
}

.c-cp-sign-bg {
  z-index: 2;
}

.c-cp-sign {
  z-index: 3;
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
}

.app .c-cp-sign {
  top: 0;
}

.c-cp-sign__body {
  position: relative;
  background-color: #fff;
}

.c-cp-sign__body {
  padding: 18px 20px 20px;
  font-size: 15px;
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.5);
}

.c-cp-sign__close {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 5px;
  right: 5px;
  background: 50% 50% no-repeat;
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Cpath%20d%3D%22M1.05%202.465L4.586%206%201.05%209.536l1.414%201.414L6%207.414l3.536%203.536%201.414-1.414L7.414%206l3.536-3.535L9.536%201.05%206%204.586%202.464%201.05%201.05%202.465z%22%20fill%3D%22%23808080%22%2F%3E%3C%2Fsvg%3E);
}

.c-cp-sign h3 {
  font-weight: 700;
  font-family: 'Open Sans', 'Helvetica Neue', HelveticaNeue, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', rouble;
}

.c-cp-sign h3 {
  margin: 0 0 2px;
  font-size: 20px;
}

.c-cp-mod {
  margin-bottom: 30px;
  overflow: hidden;
}

.c-cp-mod__title {
  font-weight: 700;
}

.c-cp-mod__nav ul {
  margin-bottom: 10px;
}

.c-cp-mod__nav li {
  margin-bottom: 5px;
}

.c-cp-mod__info {
  margin-bottom: 20px;
}

.c-cp-mod__status--pending {
  color: red;
}

.c-cp-company-data {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.c-cp-company-data {
  -webkit-flex-direction: column;
  flex-direction: column;
}

.c-cp-company-data__part {
  display: -webkit-flex;
  display: flex;
}

.c-cp-data-unit {
  padding-left: 20px;
  padding-right: 20px;
}

.c-cp-data-unit:first-child {
  padding-left: 0;
}

.c-cp-data-unit:last-child {
  padding-right: 0;
}

.c-cp-data__sub-title {
  font-weight: 500;
  color: #666;
}

a.c-cp-data__title,
p.c-cp-data__title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

p.c-cp-data__description {
  margin-bottom: 0;
}

.table--promotion-history {
  border: none;
  width: auto;
}

.table--promotion-history td {
  border: none;
}

.c-cp-mod__chart {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
}

.c-cp-chart {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.c-cp-chart__y-axis {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  height: 125px;
  margin-right: 3px;
}

.c-cp-chart__y-axis > li {
  font-size: 14px;
  text-align: right;
}

.c-cp-chart__graph {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.c-cp-graph {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  height: 125px;
  margin-bottom: 5px;
}

.c-cp-graph-col {
  width: 10px;
  margin-right: 1px;
  position: relative;
  cursor: pointer;
}

.c-cp-graph-col__toltip {
  display: none;
  background-color: #f7ff6e;
  position: absolute;
  top: -85px;
  left: 10px;
  width: 120px;
  line-height: 1;
  font-size: 11px;
  padding: 5px;
  height: 65px;
  z-index: 1;
}

.c-cp-graph-col__section--post {
  background-color: #4959ca;
}

.c-cp-graph-col__section--mini {
  background-color: #e36654;
}

.c-cp-graph-col__section--card {
  background-color: #f0f;
}

.c-cp-graph-col__section--cpc {
  background-color: #00c300;
}

.c-cp-legend {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.c-cp-legend__section {
  width: 88px;
}

.c-cp-legend .c-cp-legend__title {
  margin: 0;
  font-size: 12px;
  line-height: 1;
}

.c-cp-legend .c-cp-legend__text {
  margin: 0;
  font-size: 10px;
  line-height: 1.2;
}

.c-cp-color--post {
  color: #4959ca;
}

.c-cp-color--card {
  color: #f0f;
}

.c-cp-color--cpc {
  color: #00c300;
}

.c-cp-color--mini {
  color: #e36654;
}

.c-booking-modal__success {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}

.c-booking-modal__success {
  margin-top: 28px;
  font-size: 17px;
}

.c-booking-modal__icon {
  width: 140px;
  height: 140px;
  background-repeat: no-repeat;
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20140%20140%22%20width%3D%22140%22%20height%3D%22140%22%3E%3Ccircle%20cx%3D%2270%22%20cy%3D%2270%22%20r%3D%2270%22%20fill%3D%22%23206020%22%2F%3E%3Cpath%20d%3D%22M34.41%2080.56l20.828%2025.609%2043.728-56.138V36.17L55.279%2092.673%2034.41%2067.226V80.56z%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E);
  background-size: 100% 100%;
}

.c-booking-modal__icon {
  margin-bottom: 16px;
}

.c-booking-modal__header {
  font-weight: 700;
}

.c-booking-modal__header {
  margin-bottom: 3px;
}

.c-booking-modal__text {
  margin-bottom: 17px;
}

.c-booking-form {
  margin: -10px -10px 0;
}

.c-booking-form__hours {
  margin: 5px 10px 5px;
}

.c-booking-form__description {
  height: 21px !important;
  transition: height ease-in-out 0.15s;
}

.c-booking-form__description:focus {
  height: 62px !important;
}

.c-booking-form__description + .c-charcounter {
  opacity: 0;
  transition: opacity ease-in-out 0.15s;
}

.c-booking-form__description:focus + .c-charcounter {
  opacity: 1;
}

.c-booking-form__contacts {
  margin: 5px 10px 0;
}

.c-booking-form__contacts-notes .c-booking-form__contact-by-phone {
  display: none;
}

.c-booking-form__contacts-notes.is-phone .c-booking-form__contact-by-phone {
  display: block;
}

.c-booking-form__contacts-notes .c-booking-form__contact-by-messages {
  display: block;
}

.c-booking-form__contacts-notes.is-phone .c-booking-form__contact-by-messages {
  display: none;
}

.c-hours {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.c-hours {
  font-size: 15px;
}

.c-hours__container {
  margin: -1px 0 0 -1px;
}

.c-hours__item {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  cursor: pointer;
}

.c-hours__item {
  width: 25%;
}

.c-hours__item::after {
  content: '';
  display: block;
  padding-top: 100%;
}

.c-hours input {
  position: absolute;
  width: 0;
  height: 0;
  border: 0;
  overflow: hidden;
  opacity: 0.001;
  clip: rect(0 0 0 0);
}

.c-hours__label {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 0;
  right: -1px;
  bottom: -1px;
  left: 0;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  border: 1px solid #ccc;
  background-color: #fff;
}

.c-hours input:checked + .c-hours__label {
  z-index: 1;
  color: #fff;
  border-color: #1f53a4;
  background-color: #3870c6;
}

.c-cp-highlights {
  padding-top: 8px;
  font-size: 15px;
  line-height: 1.34;
}

.c-cp-highlights__group {
  margin-bottom: 37px;
}

.c-cp-highlights__category {
  color: grey;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

.c-cp-highlights__category {
  text-align: center;
  margin-bottom: 13px;
  font-size: 13px;
}

.c-cp-highlights__items {
  margin: 0 -20px;
}

.c-cp-highlights__item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 14px;
}

.c-cp-highlights__icon {
  -webkit-flex: none;
  flex: none;
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.c-cp-highlights__icon {
  width: 54px;
  height: 45px;
  margin: 0 -5px -15px 0;
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
}

.c-cp-highlights__icon:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 10px;
}

.c-cp-highlights__icon:after {
  background: linear-gradient(to bottom, rgba(240, 240, 240, 0) 0, #f0f0f0 100%) repeat-y;
}

.c-cp-highlights__text {
  position: relative;
}

.c-cp-highlights__text {
  min-width: 0;
  margin-right: 10px;
}

.c-cp-highlights-button {
  margin-top: 3px;
}

.c-cp-bonus {
  background-color: #5fa03d;
  color: #fff;
  text-align: center;
  padding: 15px;
  border-radius: 6px;
  line-height: 1.3;
}

.c-cp-bonus p {
  margin: 0;
  font-size: 13px;
}

.c-cp-bonus__description {
  font-size: 13px;
}

.c-cp-bonus__description::before {
  content: ' ';
  background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%2211%22%20fill%3D%22%23BB2739%22%2F%3E%0A%3Cpath%20d%3D%22M14.7535%2013.934V8.519C14.7535%205.308%2013.2145%204.472%2011.0485%204.472H7.51454V18H11.0485C13.3285%2018%2014.7535%2016.993%2014.7535%2013.934ZM12.2455%2013.782C12.2455%2015.112%2011.7705%2015.701%2010.8965%2015.701H10.0035V6.771H10.9155C11.7325%206.771%2012.2455%207.189%2012.2455%208.671V13.782Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E%0A);
  background-size: 100% 100%;
  width: 22px;
  height: 25px;
  margin: -1px 5px 1px 0;
  display: inline-block;
  vertical-align: middle;
}

.c-cp-bonus__value {
  display: block;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 10px;
}

@-webkit-keyframes cp-fadein-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes cp-fadein-up {
  0% {
    opacity: 0;
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes cp-zoomin {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes cp-zoomin {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes cp-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes cp-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes cp-header {
  0% {
    opacity: 0;
    -webkit-transform: translateY(15px) scale(0.95);
    transform: translateY(15px) scale(0.95);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
}

@keyframes cp-header {
  0% {
    opacity: 0;
    -webkit-transform: translateY(15px) scale(0.95);
    transform: translateY(15px) scale(0.95);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
}

.fx-cp-active {
  opacity: 1 !important;
}

.fx-cp-fadein-up {
  -webkit-animation: 1s cp-fadein-up forwards;
  animation: 1s cp-fadein-up forwards;
}

.fx-cp-fadein-up-2 {
  -webkit-animation: 1.2s cp-fadein-up forwards;
  animation: 1.2s cp-fadein-up forwards;
}

.fx-cp-fadein-up-3 {
  -webkit-animation: 0.8s cp-fadein-up forwards;
  animation: 0.8s cp-fadein-up forwards;
}

.fx-cp-fadein-up-4 {
  -webkit-animation: 1.4s cp-fadein-up forwards;
  animation: 1.4s cp-fadein-up forwards;
}

.fx-cp-zoomin {
  -webkit-animation: 1.1s cp-zoomin forwards;
  animation: 1.1s cp-zoomin forwards;
}

.fx-cp-fadein {
  -webkit-animation: 1s cp-fadein forwards;
  animation: 1s cp-fadein forwards;
}

.fx-cp-header {
  -webkit-animation: 1s cp-header forwards;
  animation: 1s cp-header forwards;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.c-fadeinup {
  opacity: 0;
}

.c-cp {
  background-color: #fff;
  font-family: 'Open Sans', 'Helvetica Neue', HelveticaNeue, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', rouble;
}

.c-cp {
  margin-top: 50px;
}

.app .c-cp {
  margin-top: 0;
}

.c-cp .o-grid > * {
  font-family: 'Open Sans', 'Helvetica Neue', HelveticaNeue, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', rouble;
}

.app .c-cp .o-grid > * {
  font-family: 'Open Sans', 'Helvetica Neue', HelveticaNeue, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', rouble;
}

.c-cp .c-link:not(.c-link--nd) {
  background-position: 0 91% !important;
}

.c-cp button,
.c-cp input,
.c-cp select,
.c-cp textarea {
  font-family: 'Open Sans', 'Helvetica Neue', HelveticaNeue, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', rouble;
}

.app .c-cp button,
.app .c-cp input,
.app .c-cp select,
.app .c-cp textarea {
  font-family: 'Open Sans', 'Helvetica Neue', HelveticaNeue, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', rouble;
}

.c-user-card-inline {
  font-size: inherit;
}

.c-user-card-inline__pic {
  width: 16px;
}

.c-cp .c-button:not(.c-button--round) {
  font-weight: 700;
  font-size: 13px;
}

.c-cp .c-button:not(.c-button--round) {
  padding: 0 10px;
  font-size: 14px;
}

.c-cp .c-button--subs.c-button {
  width: 114px;
}

.c-cp .c-button--l:not(.c-button--round) {
  height: 40px;
  font-size: 15px;
  padding-left: 29px;
  padding-right: 29px;
  line-height: 37px;
}

.c-cp .c-button--l:not(.c-button--round) {
  font-size: 17px;
}

.c-cp .c-button--subs.c-button--l {
  width: 184px;
}

.c-cp .c-button--xl:not(.c-button--round) {
  font-size: 20px;
}

.c-cp .c-button--xl:not(.c-button--round) {
  font-size: 20px;
}

.c-cp .c-button--solid {
  border-top-color: rgba(255, 255, 255, 0.2);
  border-right-color: transparent;
  border-bottom-color: rgba(0, 0, 0, 0.3);
  border-left-color: rgba(255, 255, 255, 0.15);
}

.c-cp .c-button--solid.is-pressed,
.c-cp .c-button--solid:active {
  border-top-color: rgba(0, 0, 0, 0.3);
  border-right-color: rgba(255, 255, 255, 0.15);
  border-bottom-color: rgba(255, 255, 255, 0.2);
  border-left-color: transparent;
}
