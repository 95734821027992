.c-cp-feedback-container {
  padding-left: 10px;
  padding-right: 10px;
}

.c-post-preview__lead {
  max-height: 87px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.c-post-preview__lead {
  margin-bottom: 10px;
}

.c-com-feedback-card {
  margin-bottom: 5px;
}
