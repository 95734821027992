@keyframes _marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.promo {
  overflow: hidden;
}

.promo::before {
  content: '';
  display: block;
  margin-left: -10px;
  width: 1160px;
  background: url('../promo.png') repeat-x left top / auto 100%;
  height: 180px;
  animation-name: _marquee;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
