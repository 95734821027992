.c-companies-form__header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-bottom: 0;
  height: 70px;
  background: #6a9f49 url(https://drive2.ru/images/com/companies-search-bg-mobile.jpg) no-repeat;
  background-size: cover;
  font-size: 17px;
  color: #fff;
  border-radius: 6px 6px 0 0;
  text-align: center;
  overflow: hidden;
}
.m .c-companies-form__header {
  isolation: isolate;
}
.c-companies-form__filters {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: -10px;
  margin-bottom: 20px;
}
.c-companies-form__filters-group {
  margin-top: 20px;
  margin-bottom: 15px;
}
.c-companies-form__filter {
  margin-top: 12px;
  margin-right: 10px;
}
.c-companies-form__separator {
  margin: 0;
  border: 0;
  background: 0 0;
}
.c-companies-form__separator {
  width: 100%;
  display: block;
  margin-top: 8px;
}
.c-companies__item {
  display: block;
}
.c-companies__item > div {
  display: -webkit-flex;
  display: flex;
  color: #333;
  font-size: 15px;
}
.c-companies__body {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
}
.c-companies__avatar {
  -webkit-order: 2;
  order: 2;
  -webkit-flex: none;
  flex: none;
  width: 50px;
  height: 50px;
  margin-left: 10px;
}
.c-companies__title {
  margin: 6px 0 11px;
  font-size: 21px;
  line-height: 1.3;
  font-weight: 700;
  line-height: 26px;
}
.c-companies__address {
  margin-top: 8px;
  font-size: 13px;
  color: grey;
}
.c-companies__working-hours {
  font-size: 13px;
  color: grey;
  margin-top: 5px;
}
.c-companies__working-hours.is-open {
  color: #628f3d;
}
.c-companies__working-hours.is-closed {
  color: #b92739;
}
.c-companies__stats {
  margin: 5px 0 5px;
  font-size: 13px;
  color: grey;
}
.c-companies__stats span {
  font-weight: 700;
  color: #333;
}
