main {
  position: relative;
  z-index: 1;
  background: white;
}

.title {
  font-size: 28px;
}

.c-companies-form__header {
  background: #5da036;
  height: auto;
  padding: 20px 0;
}

.factoid {
  text-align: center;
  margin: 20px auto;
  font-size: 17px;
  line-height: 1.4;
  max-width: 250px;
}

.c-companies__brands,
.c-companies__service,
.c-companies__address {
  margin-top: 2px;
  font-size: 13px;
  color: black;
  margin-right: -60px;
}

.c-companies__description {
  margin-bottom: 10px;
}

.c-companies__title {
  margin: 0;
  font-size: 17px;
}

.c-companies__working-hours {
  margin-top: 2px;
}

.c-companies__working-hours {
  font-weight: 700;
}

.grey {
  color: #808080;
}

.additional-list-controls {
  text-align: center;
}
